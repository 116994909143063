import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return(
            <div id="Footer">
            </div>
        );
    }
};

export default Footer;